<template>
  <div>
    <div class="is-flex">
      <p class="has-text-weight-bold is-size-4">{{ $t("af:my_todo.title") }}</p>
      <p class="af-text-button" @click="backToTodos">
        {{ $t("af:my_todo.button.back") }}
      </p>
    </div>

    <div class="af-card--user">
      <template v-if="loaded">
        <div class="is-flex">
          <p class="has-text-weight-bold is-size-5">
            {{ $t("af:my_todo.card.correction.title") }}
          </p>

          <span
            class="correction-badge"
            :class="{
              completed:
                correction.status === CORRECTION_STATUS.COMPLETED ||
                correction.status === CORRECTION_STATUS.CLOSED,
              'in-progress':
                correction.status !== CORRECTION_STATUS.COMPLETED &&
                correction.status !== CORRECTION_STATUS.CLOSED,
            }"
          >
            {{ $t(TODO_TYPE.STATUS.CORRECTION[correction.status]) }}
          </span>
        </div>

        <template>
          <p class="has-text-weight-bold mb-1">
            {{ $t("af:my_todo.card.correction.label.title") }}
          </p>

          <p class="has-text-grey-icon mb-5">
            {{ correction.title }}
          </p>
        </template>

        <template v-if="correction.description">
          <p class="has-text-weight-bold mb-1">
            {{ $t("af:my_todo.card.correction.label.description") }}
          </p>

          <p class="has-text-grey-icon mb-5">
            {{ correction.description }}
          </p>
        </template>

        <template v-if="correction.dueDate">
          <p class="has-text-weight-bold mb-1">
            {{ $t("af:my_todo.card.correction.label.due_date") }}
          </p>

          <p class="has-text-grey-icon mb-5">
            {{ formatDateTime(correction.dueDate) }}
          </p>
        </template>

        <template v-else>
          <p class="has-text-weight-bold mb-1">
            {{ $t("af:my_todo.card.correction.label.created") }}
          </p>

          <p class="has-text-grey-icon mb-5">
            {{ formatDateTime(correction.created) }}
          </p>
        </template>

        <template v-if="correction.completed">
          <p class="has-text-weight-bold mb-1">
            {{ $t("af:my_todo.card.correction.label.completed") }}
          </p>

          <p class="has-text-grey-icon mb-5">
            {{ formatDateTime(correction.completed) }}
          </p>
        </template>

        <user-correction-document
          v-for="document in correction.documents"
          :key="document.id"
          :document="document"
          :show-name="isShowNameForDocument(document)"
          ref="uploads"
        />

        <div v-if="correction.hasPsd2Field" class="is-flex">
          <Psd2Field
            :label="$t('af:preliminary.psd2_sync.field.title')"
            :description="$t('af:preliminary.psd2_sync.field.description')"
            :skippable="false"
            :source="PSD2_FIELD_SOURCE.CORRECTION"
            :correctionId="id"
            class="mt-5 ml-auto mr-auto"
            :readonly="correction.completed"
            ref="psd2Field"
            :style='{"width":"650px"}'
          >
            <div class="psd2-sync-tooltip has-text-weight-bold is-flex">
              <b-icon icon="exclamation-circle" />
              <span class="ml-2">
                {{ $t("af:preliminary.psd2_sync.field.content.tooltip") }}
              </span>
            </div>

            <div class="is-flex mt-5">
              <b-icon icon="user-lock" class="is-blue-icon" size="is-large" />

              <div class="ml-4 is-flex is-flex-direction-column">
                <span class="has-text-weight-bold">
                  {{
                    $t("af:preliminary.psd2_sync.field.content.security.title")
                  }}
                </span>

                <span>
                  {{
                    $t(
                      "af:preliminary.psd2_sync.field.content.security.description"
                    )
                  }}
                </span>
              </div>
            </div>
          </Psd2Field>
        </div>

        <template v-if="correction.status === CORRECTION_STATUS.IN_PROGRESS">
          <div class="is-flex mb-0 mt-5">
            <div class="ml-auto">
              <b-button
                :value="$t('af:my_todo.button.cancel')"
                tag="input"
                type="is-text"
                class="mr-4"
                @click="backToTodos"
              ></b-button>
              <b-button
                type="is-blue"
                :value="$t('af:my_todo.button.save')"
                tag="input"
                @click="save"
              ></b-button>
            </div>
          </div>
        </template>
        <b-loading
          :is-full-page="true"
          v-model="submitInProgress"
          :can-cancel="false"
        />
      </template>

      <loading-skeleton v-else />
    </div>
  </div>
</template>

<script>
import { CORRECTION_STATUS, PSD2_FIELD_SOURCE, TODO_TYPE } from "@/utils/const";
import LoadingSkeleton from "../components/LoadingSkeleton";
import { formatDateTime } from "@/utils/util";
import UserCorrectionDocument from "../components/user/todos/UserCorrectionDocument";
import i18n from "../plugins/i18n";
import Psd2Field from "../components/psd2/Psd2Field";

export default {
  name: "UserSubmitMissingDocuments",
  title: "af:page.my_todo.title",
  components: { UserCorrectionDocument, LoadingSkeleton, Psd2Field },
  props: {
    id: {
      type: String,
      required: true,
    },
    mortgage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      formatDateTime,
      TODO_TYPE,
      CORRECTION_STATUS,
      PSD2_FIELD_SOURCE,
      correction: {},
      loaded: false,
      submitInProgress: false,
    };
  },
  async mounted() {
    try {
      if (this.mortgage) {
        await this.$store.dispatch("correction/fetchMortgage", this.id);
      } else {
        await this.$store.dispatch("correction/fetch", this.id);
      }
    } catch (ex) {
      if (ex.response.status === 404) {
        this.backToTodos();
      }
    }
    this.correction = this.mortgage
      ? this.$store.getters["correction/getMortgage"](this.id)
      : this.$store.getters["correction/get"](this.id);
    this.correction.documents.sort((a, b) => a.id - b.id);
    this.loaded = true;
  },
  methods: {
    backToTodos() {
      this.$router.push({
        name: "UserTodos",
      });
    },
    isShowNameForDocument(currentDocument) {
      return (
        this.correction.documents
          .filter((document) => document.name === currentDocument.name)
          .indexOf(currentDocument) === 0
      );
    },
    async save() {
      const formData = new FormData();

      if (this.$refs.uploads) {
        let uploadsWithDocument = this.$refs.uploads.filter(
          (upload) => upload.uploadedFiles.length > 0
        );

        if (uploadsWithDocument.length !== this.$refs.uploads.length) {
          this.$buefy.toast.open({
            message: i18n.t(
              "af:my_todo.card.correction.upload.message.required"
            ),
            type: "is-danger",
          });
          return;
        }

        this.submitInProgress = true;

        let files = [];
        let documents = [];
        let index = 0;

        uploadsWithDocument.forEach((upload) => {
          upload.uploadedFiles.forEach((file) => {
            files.push(file);
          });
          documents.push({
            documentId: upload.document.id,
            index: index++,
            filesSize: upload.uploadedFiles.length,
          });
        });

        files.forEach((file) => formData.append("files", file));
        formData.append("documents", JSON.stringify(documents));
      }

      if (this.$refs.psd2Field) {
        if (!this.$refs.psd2Field.isSynced()) {
          this.$buefy.toast.open({
            message: i18n.t(
              "af:my_todo.card.correction.upload.message.required"
            ),
            type: "is-danger",
          });
          return;
        }
      }

      try {
        if (this.mortgage) {
          await this.$store.dispatch("correction/completeMortgage", {
            id: this.id,
            data: formData,
          });
        } else {
          await this.$store.dispatch("correction/complete", {
            id: this.id,
            data: formData,
          });
        }
        this.$buefy.toast.open({
          message: i18n.t("af:my_todos.card.correction.outcome.successful"),
          type: "is-success",
        });
        if (this.mortgage) {
          await this.$store.dispatch("myTodo/fetchMortgageCount");
        } else {
          await this.$store.dispatch("myTodo/fetchCount");
        }
        this.backToTodos();
      } catch (ex) {
        console.error(ex);
        this.$buefy.toast.open({
          message: i18n.t("af:my_todos.card.correction.outcome.unsuccessful"),
          type: "is-danger",
        });
      }
      this.submitInProgress = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.af-text-button {
  font-weight: 500;
  margin-left: auto;
  color: $grey-icon;
}

.correction-badge {
  padding: 0.5rem 1rem;
  border-radius: 6px;
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  margin-left: auto;
  margin-bottom: 1rem;

  &.in-progress {
    background-color: black;
  }

  &.completed {
    background-color: $cyan;
  }
}

.button.is-text {
  text-decoration: none;
}
</style>
