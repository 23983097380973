<template>
  <span class="uploaded-file-container mt-4">
    <b-icon icon-pack="fa" icon="file" class="mr-2"></b-icon>

    <label>
      {{ documentName }}
    </label>

    <span class="ml-auto">
      <span class="cursor-pointer" @click="deleteFile" v-if="uploadMode">
        <b-icon icon-pack="fa" icon="trash" type="is-red" class="mr-2"></b-icon>
      </span>

      <span class="cursor-pointer" @click="downloadFile">
        <b-icon
          icon-pack="fa"
          icon="download"
          type="is-blue"
          class="mr-2"
        ></b-icon>
      </span>
    </span>
  </span>
</template>

<script>
import FileSaver from "file-saver";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UserCorrectionDocumentCard",
  props: {
    uploadMode: {
      type: Boolean,
      default: false,
    },
    documentName: {
      type: String,
      required: true,
    },
    documentId: {
      type: String,
      required: false,
    },
    uploadedFile: {
      type: File,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      getFile: "drawer/getFile",
    }),
  },
  methods: {
    ...mapActions({
      download: "drawer/download",
    }),
    async downloadFile() {
      if (this.uploadMode) {
        FileSaver.saveAs(this.uploadedFile);
      } else {
        await this.download(this.documentId);
        let blob = this.getFile(this.documentId);
        FileSaver.saveAs(blob, this.documentName);
      }
    },
    deleteFile() {
      this.$emit("delete");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/colors.scss";

.user-correction-document {
  .upload {
    background: $grey-2;

    .upload-draggable:hover.is-primary {
      background: $grey-2 !important;
      border-color: $blue !important;
    }
  }
}

.uploaded-file-container {
  display: flex;
  background-color: $grey-2;
  padding: 0.25rem 0.75rem;
  border-radius: 6px;
}

.has-text-red {
  color: $red;
}
</style>
