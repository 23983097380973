<template>
  <div class="user-correction-document">
    <template v-if="showName">
      <p class="has-text-weight-bold mb-1 mt-5">
        {{ document.name }}
      </p>

      <p class="has-text-grey-icon mb-4">
        {{ document.description }}
      </p>
    </template>

    <template v-if="!document.documentId">
      <b-upload
        multiple
        v-model="uploadedFiles"
        drag-drop
        expanded
        accept="application/pdf, image/jpeg, image/png, image/tiff, image/heic, image/heif, image/avif, image/webp, image/bmp"
      >
        <section class="section">
          <div class="content has-text-centered">
            {{ $t("af:my_todo.card.correction.upload.label") }}
          </div>
        </section>
      </b-upload>
    </template>

    <template v-if="uploadedFiles && uploadedFiles.length > 0">
      <user-correction-document-card
        v-for="(uploadedFile, index) in uploadedFiles"
        :key="index"
        :upload-mode="true"
        :document-name="uploadedFile.name"
        :uploaded-file="uploadedFile"
        @delete="deleteFile(index)"
      />
    </template>

    <template v-if="document.documentId">
      <user-correction-document-card
        :upload-mode="false"
        :document-name="document.fileName"
        :document-id="document.documentId"
      />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UserCorrectionDocumentCard from "./UserCorrectionDocumentCard";

export default {
  name: "UserCorrectionDocument",
  components: { UserCorrectionDocumentCard },
  props: {
    document: {
      type: Object,
      required: true,
    },
    showName: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      uploadedFiles: [],
    };
  },
  computed: {
    ...mapGetters({
      getFile: "drawer/getFile",
    }),
  },
  methods: {
    ...mapActions({
      download: "drawer/download",
    }),
    deleteFile(index) {
      this.uploadedFiles.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/colors.scss";

.user-correction-document {
  .upload {
    background: $grey-2;

    .upload-draggable:hover.is-primary {
      background: $grey-2 !important;
      border-color: $blue !important;
    }
  }
}

.mbh {
  .user-correction-document {
    .upload {
      background: white;
    }

    .upload-draggable {
      border: 2px dashed #efefef;
    }
  }
}
</style>
